import Cleave from 'cleave.js/react';
import 'cleave.js/dist/addons/cleave-phone.gb';
import { CleaveOptions } from 'cleave.js/options';
import React, { FC, useState } from 'react';
import { ChangeEvent } from 'react';
import Button from './Button';

type IBaseTarget = EventTarget & HTMLInputElement & { rawValue: string };

interface IOnChangeEvent extends ChangeEvent<HTMLInputElement> {
    target: IBaseTarget;
}

export interface TextInputProps extends React.HTMLProps<HTMLInputElement> {
    onChange?: (event: IOnChangeEvent) => void;
    id: string;
    name: string;
    label?: string;
    hideLabel?: boolean;
    value?: string;
    placeholder?: string;
    error?: boolean;
    bottomMargin?: 'normal' | 'small';
    required?: boolean;
    showPassword?: boolean;
    hideShowPasswordButton?: boolean;
    toggleDisplayPassword?: (showPassword: boolean) => void;
    formatOptions?: CleaveOptions;
}

const TextInput: FC<TextInputProps> = ({
    disabled = false,
    onChange,
    autoFocus,
    className = '',
    id,
    name,
    label,
    hideLabel = false,
    value = undefined,
    placeholder = '',
    error = false,
    type = 'text',
    bottomMargin = 'normal',
    required = false,
    showPassword,
    toggleDisplayPassword,
    hideShowPasswordButton = false,
    formatOptions = null,
    ...props
}) => {
    const InputTag = formatOptions ? Cleave : 'input';
    const maxPhoneNumberLength = 15;
    const [passwordShownState, setPasswordShownState] =
        useState<boolean>(false);

    let passwordShown = null;

    if (showPassword !== undefined) {
        passwordShown = showPassword;
    } else {
        passwordShown = passwordShownState;
    }

    const onToggleDisplayPassword = newShownState => {
        if (showPassword !== undefined) {
            toggleDisplayPassword(newShownState);
        } else {
            setPasswordShownState(newShownState);
        }
    };

    const _onChange = event => {
        if (type === 'date') {
            const dateStringSplit = event.target.value.split('/');

            if (dateStringSplit[2] && dateStringSplit[2].length === 4) {
                event.target.rawValue = `${dateStringSplit[2]}-${dateStringSplit[1]}-${dateStringSplit[0]}`;

                onChange(event);
            }
        } else {
            onChange(event);
        }
    };

    return (
        <div
            className={`${className} flex flex-col
                    ${bottomMargin === 'normal' ? 'mb-lg' : ''}
                    ${bottomMargin === 'small' ? 'mb-md' : ''}
                `}
        >
            <div className="flex justify-between">
                {label && !hideLabel && (
                    <label
                        className={`mb-5xsm ${
                            error ? 'text-danger-700' : 'text-neutral-800'
                        }`}
                        htmlFor={id}
                    >
                        {label}
                    </label>
                )}
                {!required && (
                    <span className="text-neutral-600">Optional</span>
                )}
                {!hideShowPasswordButton && type === 'password' && (
                    <Button
                        kind="text"
                        className="text-primary-600 tracking-normal focus:outline-none"
                        onClick={() => onToggleDisplayPassword(!passwordShown)}
                    >
                        {passwordShown ? `Hide` : `Show`}
                    </Button>
                )}
            </div>
            <InputTag
                options={formatOptions}
                className={`
                    rounded-sm transition tracking-normal leading-0 focus:outline-none border h-form-input pt-4xsm px-md

                    ${
                        error
                            ? '!border-danger-500 !bg-danger-100 !text-danger-700 hover:!border-danger-600 !placeholder-danger-700 focus:!border-danger-500'
                            : ''
                    }

                    ${
                        !disabled
                            ? 'border-neutral-500 bg-neutral-100 text-neutral-800 hover:border-neutral-600 placeholder-neutral-600 focus:border-primary-500'
                            : 'border-neutral-500 bg-neutral-300 text-neutral-600'
                    }

                `}
                autoFocus={autoFocus}
                value={value}
                type={
                    type === 'date' || (type === 'password' && passwordShown)
                        ? 'text'
                        : type
                }
                name={name}
                id={id}
                placeholder={placeholder}
                onChange={_onChange}
                required={required}
                disabled={disabled}
                maxLength={
                    formatOptions?.phone ? maxPhoneNumberLength : Infinity
                }
                {...props}
            />
        </div>
    );
};

export default TextInput;
