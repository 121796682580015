import React, { FormEventHandler, HTMLProps } from 'react';
import { FC } from 'react';

interface Props extends HTMLProps<HTMLFormElement> {
    onSubmit: FormEventHandler<HTMLFormElement>;
    preventDefault?: boolean;
}

const Form: FC<Props> = ({
    children,
    onSubmit,
    preventDefault = true,
    ...props
}) => {
    return (
        <form
            onSubmit={e => {
                if (preventDefault) {
                    e.preventDefault();
                }

                onSubmit(e);

                return false;
            }}
            method="post"
            {...props}
        >
            {children}
        </form>
    );
};

export default Form;
