import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

interface Props {
    className?: string;
}

const LogoText: FC<Props> = ({ className = '' }: Props) => {
    const { t } = useTranslation();
    return (
        <svg
            viewBox="0 0 180 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            preserveAspectRatio="none"
            className={`w-full h-full ${className}`}
        >
            <title>{t('common:simpleOnlinePharmacy')}</title>
            <path
                d="M3.68266 12.6092C2.56288 12.6092 1.68493 12.3201 1.04881 11.7418C0.417946 11.1583 0.0683434 10.5011 0 9.77036H1.58504C1.66916 10.1804 1.91099 10.53 2.31053 10.8192C2.71008 11.1083 3.15694 11.2476 3.65112 11.2371C4.14529 11.2266 4.53432 11.1031 4.81821 10.8665C5.10736 10.6299 5.25193 10.3224 5.25193 9.94384C5.25193 9.55481 5.07318 9.26041 4.7157 9.06063C4.36346 8.8556 3.83775 8.67686 3.13854 8.5244C2.73899 8.44029 2.38939 8.34829 2.08973 8.2484C1.79533 8.14326 1.4983 8.00131 1.19864 7.82257C0.898979 7.64382 0.667663 7.40988 0.50469 7.12073C0.341717 6.83159 0.260231 6.4925 0.260231 6.10347C0.260231 5.30438 0.578291 4.66563 1.21441 4.18722C1.85053 3.70882 2.64699 3.46962 3.6038 3.46962C4.50804 3.46962 5.27033 3.69568 5.89068 4.14779C6.51103 4.59991 6.86326 5.24655 6.94737 6.0877H5.3781C5.3045 5.68815 5.1021 5.39112 4.7709 5.1966C4.44495 5.00209 4.10061 4.89431 3.73786 4.87329C3.28574 4.85226 2.90722 4.93111 2.60231 5.10986C2.30265 5.2886 2.15282 5.54883 2.15282 5.89055C2.15282 6.05352 2.19488 6.20073 2.27899 6.33215C2.36836 6.45833 2.45773 6.56084 2.54711 6.6397C2.63648 6.7133 2.79682 6.79216 3.02814 6.87627C3.25946 6.95513 3.4382 7.01033 3.56437 7.04187C3.69055 7.07342 3.90872 7.12599 4.21889 7.19959C4.60792 7.28371 4.94701 7.38359 5.23616 7.49925C5.5253 7.61491 5.82233 7.77 6.12725 7.96451C6.43743 8.15377 6.67663 8.40874 6.84486 8.72943C7.01309 9.04486 7.0972 9.41287 7.0972 9.83344C7.0972 10.6536 6.78177 11.3212 6.15091 11.8364C5.52005 12.3516 4.6973 12.6092 3.68266 12.6092Z"
                fill="#000920"
            />
            <path
                d="M8.62704 3.60367H10.4487V12.4831H8.62704V3.60367Z"
                fill="#000920"
            />
            <path
                d="M22.8293 3.51693C23.7756 3.51693 24.5195 3.79556 25.061 4.35282C25.6078 4.90483 25.8811 5.67238 25.8811 6.65547V12.4831H24.0201V6.8605C24.0201 6.31375 23.8703 5.89055 23.5706 5.59089C23.2762 5.28597 22.8556 5.13352 22.3089 5.13352C21.7253 5.13352 21.218 5.307 20.7869 5.65398C20.3611 5.9957 20.1482 6.44256 20.1482 6.99456V12.4831H18.2871V6.8605C18.2871 6.31375 18.1373 5.89055 17.8376 5.59089C17.5432 5.28597 17.1227 5.13352 16.5759 5.13352C15.9924 5.13352 15.485 5.307 15.054 5.65398C14.6229 5.9957 14.4073 6.44256 14.4073 6.99456V12.4831H12.5542V3.60367H14.2181L14.2969 4.77866C14.5966 4.38962 14.9961 4.08208 15.4956 3.85602C16.0002 3.62996 16.526 3.51693 17.0727 3.51693C18.2135 3.51693 19.0862 4.05054 19.6908 5.11774C20.001 4.6446 20.4452 4.26082 21.0235 3.96642C21.6018 3.66676 22.2037 3.51693 22.8293 3.51693Z"
                fill="#000920"
            />
            <path
                d="M29.3272 4.76288C29.9949 3.93225 30.9254 3.51693 32.1188 3.51693C33.3174 3.51693 34.2953 3.94013 35.0523 4.78654C35.8093 5.63295 36.1878 6.70279 36.1878 7.99605C36.1878 9.28407 35.8041 10.3644 35.0365 11.2371C34.2742 12.1098 33.2964 12.5461 32.103 12.5461C30.9622 12.5461 30.0369 12.1151 29.3272 11.2529V15.0331L27.4977 15.0173V3.60367H29.1774L29.3272 4.76288ZM31.7955 10.9296C32.5578 10.9296 33.1755 10.6509 33.6486 10.0937C34.127 9.53115 34.3662 8.83195 34.3662 7.99605C34.3662 7.1549 34.1297 6.4557 33.6565 5.89844C33.1834 5.33592 32.563 5.05466 31.7955 5.05466C31.2014 5.05466 30.6809 5.24655 30.2341 5.63032C29.7925 6.00884 29.4902 6.50564 29.3272 7.12073V8.86349C29.4797 9.47858 29.7793 9.97801 30.2262 10.3618C30.6783 10.7403 31.2014 10.9296 31.7955 10.9296Z"
                fill="#000920"
            />
            <path
                d="M37.765 12.4831L37.7808 0.966919H39.492V12.4831H37.765Z"
                fill="#000920"
            />
            <path
                d="M49.5306 8.01183C49.5306 8.26943 49.5201 8.46657 49.499 8.60326H43.0721C43.1142 9.37607 43.3718 9.98853 43.8449 10.4406C44.3181 10.8875 44.8675 11.1162 45.4931 11.1267C46.0398 11.1267 46.5156 11.0058 46.9204 10.764C47.3252 10.5221 47.6143 10.1857 47.7878 9.75458H49.3019C49.181 10.4958 48.7998 11.153 48.1585 11.726C47.5223 12.2938 46.6391 12.5724 45.5088 12.5619C44.2471 12.5619 43.2298 12.1361 42.457 11.2844C41.6895 10.4275 41.3057 9.34715 41.3057 8.04337C41.3057 6.71856 41.679 5.63558 42.4255 4.79443C43.1773 3.95328 44.2051 3.5327 45.5088 3.5327C46.7495 3.5327 47.73 3.94014 48.4502 4.755C49.1705 5.56986 49.5306 6.65547 49.5306 8.01183ZM45.4931 4.99157C44.8149 4.99157 44.2734 5.1966 43.8686 5.60666C43.4638 6.01147 43.2088 6.57136 43.1037 7.28633H47.8036C47.7195 6.57661 47.4803 6.01672 47.086 5.60666C46.6917 5.1966 46.1607 4.99157 45.4931 4.99157Z"
                fill="#000920"
            />
            <path
                d="M61.351 11.2844C60.5256 12.1571 59.4715 12.5935 58.1888 12.5935C56.9113 12.5935 55.8572 12.1571 55.0266 11.2844C54.1959 10.4065 53.7806 9.32086 53.7806 8.0276C53.7806 6.73433 54.1959 5.64872 55.0266 4.77077C55.8572 3.89282 56.9113 3.45385 58.1888 3.45385C59.4715 3.45385 60.5256 3.89282 61.351 4.77077C62.1816 5.64872 62.5969 6.73433 62.5969 8.0276C62.5969 9.32086 62.1816 10.4065 61.351 11.2844ZM56.3277 10.1094C56.8061 10.6667 57.4265 10.9453 58.1888 10.9453C58.9563 10.9453 59.5793 10.6667 60.0577 10.1094C60.5361 9.54693 60.7753 8.85298 60.7753 8.0276C60.7753 7.18645 60.5361 6.48724 60.0577 5.92998C59.5793 5.36746 58.9563 5.0862 58.1888 5.0862C57.4265 5.0862 56.8061 5.36746 56.3277 5.92998C55.8493 6.48724 55.6101 7.18645 55.6101 8.0276C55.6101 8.85823 55.8493 9.55218 56.3277 10.1094Z"
                fill="#000920"
            />
            <path
                d="M68.5901 3.48539C69.5785 3.48539 70.3618 3.77453 70.9401 4.35282C71.5236 4.92586 71.8154 5.71969 71.8154 6.73433V12.4831H69.9622V6.94725C69.9622 6.37421 69.794 5.92735 69.4575 5.60666C69.1263 5.28072 68.6637 5.11774 68.0696 5.11774C67.4335 5.11774 66.8841 5.29912 66.4215 5.66186C65.9589 6.01935 65.7276 6.47936 65.7276 7.04187V12.4831H63.8744V3.60367H65.5068L65.6487 4.84174C65.9694 4.42117 66.3952 4.08996 66.9262 3.84813C67.4624 3.6063 68.0171 3.48539 68.5901 3.48539Z"
                fill="#000920"
            />
            <path
                d="M73.913 12.4831L73.9288 0.966919H75.64L75.64 12.4831H73.913Z"
                fill="#000920"
            />
            <path
                d="M78.1003 3.60367H79.922V12.4831H78.1003V3.60367Z"
                fill="#000920"
            />
            <path
                d="M86.6485 3.48539C87.6369 3.48539 88.4202 3.77453 88.9985 4.35282C89.5821 4.92586 89.8738 5.71969 89.8738 6.73433V12.4831H88.0207V6.94725C88.0207 6.37421 87.8524 5.92735 87.516 5.60666C87.1848 5.28072 86.7221 5.11774 86.1281 5.11774C85.492 5.11774 84.9426 5.29912 84.4799 5.66186C84.0173 6.01935 83.786 6.47936 83.786 7.04187V12.4831H81.9328V3.60367H83.5652L83.7071 4.84174C84.0278 4.42117 84.4537 4.08996 84.9846 3.84813C85.5209 3.6063 86.0755 3.48539 86.6485 3.48539Z"
                fill="#000920"
            />
            <path
                d="M99.3368 8.01183C99.3368 8.26943 99.3262 8.46657 99.3052 8.60326H92.8783C92.9204 9.37607 93.178 9.98853 93.6511 10.4406C94.1243 10.8875 94.6736 11.1162 95.2992 11.1267C95.846 11.1267 96.3218 11.0058 96.7266 10.764C97.1314 10.5221 97.4205 10.1857 97.594 9.75458H99.1081C98.9872 10.4958 98.606 11.153 97.9646 11.726C97.3285 12.2938 96.4453 12.5724 95.315 12.5619C94.0533 12.5619 93.036 12.1361 92.2632 11.2844C91.4957 10.4275 91.1119 9.34715 91.1119 8.04337C91.1119 6.71856 91.4851 5.63558 92.2317 4.79443C92.9834 3.95328 94.0112 3.5327 95.315 3.5327C96.5557 3.5327 97.5362 3.94014 98.2564 4.755C98.9766 5.56986 99.3368 6.65547 99.3368 8.01183ZM95.2992 4.99157C94.6211 4.99157 94.0796 5.1966 93.6748 5.60666C93.27 6.01147 93.015 6.57136 92.9098 7.28633H97.6098C97.5257 6.57661 97.2865 6.01672 96.8922 5.60666C96.4979 5.1966 95.9669 4.99157 95.2992 4.99157Z"
                fill="#000920"
            />
            <path
                d="M105.416 4.76289C106.084 3.93225 107.014 3.51694 108.208 3.51694C109.406 3.51694 110.384 3.94014 111.141 4.78655C111.898 5.63295 112.277 6.70279 112.277 7.99606C112.277 9.28407 111.893 10.3644 111.126 11.2371C110.363 12.1098 109.385 12.5462 108.192 12.5462C107.051 12.5462 106.126 12.1151 105.416 11.2529V15.0173L103.587 15.0015V3.60368H105.266L105.416 4.76289ZM107.885 10.9296C108.647 10.9296 109.265 10.6509 109.738 10.0937C110.216 9.53116 110.455 8.83195 110.455 7.99606C110.455 7.15491 110.219 6.4557 109.746 5.89844C109.272 5.33592 108.652 5.05466 107.885 5.05466C107.29 5.05466 106.77 5.24655 106.323 5.63033C105.882 6.00884 105.579 6.50565 105.416 7.12074V8.8635C105.569 9.47859 105.868 9.97802 106.315 10.3618C106.767 10.7403 107.29 10.9296 107.885 10.9296Z"
                fill="#000920"
            />
            <path
                d="M118.31 3.51694C119.308 3.51694 120.102 3.80608 120.691 4.38437C121.28 4.95741 121.574 5.74598 121.574 6.75011V12.4831H119.697V6.96302C119.697 6.38999 119.529 5.94576 119.193 5.63033C118.862 5.30964 118.396 5.14929 117.797 5.14929C117.161 5.14929 116.604 5.32804 116.125 5.68553C115.652 6.03776 115.415 6.49513 115.415 7.05765V12.4831H113.547V0.966919H115.415V4.77866C115.736 4.38963 116.159 4.08208 116.685 3.85603C117.211 3.62997 117.752 3.51694 118.31 3.51694Z"
                fill="#000920"
            />
            <path
                d="M125.604 12.5777C124.742 12.5777 124.035 12.3411 123.483 11.868C122.936 11.3948 122.662 10.7613 122.662 9.9675C122.662 9.16841 122.981 8.51915 123.617 8.01972C124.253 7.51503 125.152 7.22062 126.314 7.13651L128.348 6.97879V6.73433C128.348 5.55147 127.725 4.96003 126.479 4.96003C125.922 4.96003 125.462 5.10198 125.099 5.38587C124.736 5.66975 124.529 6.07719 124.476 6.60816H122.891C122.965 5.72496 123.304 4.98632 123.908 4.39226C124.513 3.7982 125.386 3.50116 126.527 3.50116C127.625 3.50116 128.482 3.78242 129.097 4.34494C129.718 4.90221 130.028 5.70918 130.028 6.76588C130.028 7.26005 130.03 7.89092 130.036 8.65846C130.041 9.42076 130.044 9.8387 130.044 9.9123C130.044 10.7114 130.27 11.1004 130.722 11.0794V12.4831C130.485 12.4831 130.304 12.4778 130.178 12.4673C129.263 12.3727 128.708 12.002 128.514 11.3554C128.267 11.7129 127.872 12.0073 127.331 12.2386C126.795 12.4647 126.219 12.5777 125.604 12.5777ZM125.959 11.1583C126.516 11.1583 127.023 11.0084 127.481 10.7088C127.943 10.4039 128.232 10.0437 128.348 9.62842V8.26418L126.59 8.42978C125.16 8.55069 124.445 9.03961 124.445 9.89653C124.445 10.2856 124.594 10.5931 124.894 10.8192C125.194 11.0452 125.549 11.1583 125.959 11.1583Z"
                fill="#000920"
            />
            <path
                d="M137.164 3.50116L137.149 5.11775C136.134 5.07569 135.338 5.28861 134.759 5.7565C134.186 6.22439 133.9 6.97091 133.9 7.99606V12.4831H132.047V3.60368H133.742L133.9 5.08621C134.094 4.62357 134.452 4.2398 134.972 3.93488C135.498 3.62471 136.229 3.48014 137.164 3.50116Z"
                fill="#000920"
            />
            <path
                d="M148.741 3.51694C149.687 3.51694 150.431 3.79557 150.972 4.35283C151.519 4.90483 151.793 5.67238 151.793 6.65548V12.4831H149.932V6.86051C149.932 6.31376 149.782 5.89056 149.482 5.5909C149.188 5.28598 148.767 5.13352 148.22 5.13352C147.637 5.13352 147.129 5.30701 146.698 5.65398C146.273 5.9957 146.06 6.44256 146.06 6.99457V12.4831H144.199V6.86051C144.199 6.31376 144.049 5.89056 143.749 5.5909C143.455 5.28598 143.034 5.13352 142.487 5.13352C141.904 5.13352 141.396 5.30701 140.965 5.65398C140.534 5.9957 140.319 6.44256 140.319 6.99457V12.4831H138.466V3.60368H140.129L140.208 4.77866C140.508 4.38963 140.908 4.08208 141.407 3.85603C141.912 3.62997 142.437 3.51694 142.984 3.51694C144.125 3.51694 144.998 4.05054 145.602 5.11775C145.912 4.6446 146.357 4.26083 146.935 3.96643C147.513 3.66677 148.115 3.51694 148.741 3.51694Z"
                fill="#000920"
            />
            <path
                d="M156.011 12.5777C155.149 12.5777 154.442 12.3411 153.89 11.868C153.343 11.3948 153.07 10.7613 153.07 9.9675C153.07 9.16841 153.388 8.51915 154.024 8.01972C154.66 7.51503 155.559 7.22062 156.721 7.13651L158.756 6.97879V6.73433C158.756 5.55147 158.133 4.96003 156.887 4.96003C156.329 4.96003 155.869 5.10198 155.507 5.38587C155.144 5.66975 154.936 6.07719 154.884 6.60816H153.299C153.372 5.72496 153.711 4.98632 154.316 4.39226C154.921 3.7982 155.793 3.50116 156.934 3.50116C158.033 3.50116 158.89 3.78242 159.505 4.34494C160.125 4.90221 160.435 5.70918 160.435 6.76588C160.435 7.26005 160.438 7.89092 160.443 8.65846C160.449 9.42076 160.451 9.8387 160.451 9.9123C160.451 10.7114 160.677 11.1004 161.129 11.0794V12.4831C160.893 12.4831 160.711 12.4778 160.585 12.4673C159.67 12.3727 159.116 12.002 158.921 11.3554C158.674 11.7129 158.28 12.0073 157.738 12.2386C157.202 12.4647 156.627 12.5777 156.011 12.5777ZM156.366 11.1583C156.924 11.1583 157.431 11.0084 157.888 10.7088C158.351 10.4039 158.64 10.0437 158.756 9.62842V8.26418L156.997 8.42978C155.567 8.55069 154.852 9.03961 154.852 9.89653C154.852 10.2856 155.002 10.5931 155.302 10.8192C155.601 11.0452 155.956 11.1583 156.366 11.1583Z"
                fill="#000920"
            />
            <path
                d="M166.153 12.5777C164.901 12.5777 163.887 12.1519 163.109 11.3002C162.336 10.4433 161.949 9.34716 161.949 8.01183C161.949 6.68702 162.333 5.60667 163.101 4.77078C163.874 3.93488 164.891 3.51694 166.153 3.51694C167.404 3.51694 168.374 3.84025 169.062 4.48689C169.756 5.13352 170.153 5.89318 170.253 6.76588H168.605C168.531 6.28222 168.276 5.87478 167.84 5.54358C167.404 5.20712 166.889 5.03889 166.294 5.03889C165.506 5.03889 164.886 5.30964 164.433 5.85113C163.987 6.38736 163.763 7.10759 163.763 8.01183C163.763 8.91607 163.992 9.64419 164.449 10.1962C164.907 10.7429 165.527 11.0163 166.31 11.0163C166.883 11.0163 167.377 10.8455 167.793 10.5037C168.213 10.1568 168.484 9.73882 168.605 9.2499H170.237C170.174 9.65996 170.053 10.0516 169.875 10.4249C169.696 10.7981 169.449 11.153 169.133 11.4895C168.823 11.8207 168.411 12.0862 167.895 12.2859C167.38 12.4804 166.799 12.5777 166.153 12.5777Z"
                fill="#000920"
            />
            <path
                d="M180 3.60368C177.937 8.10409 177.331 10.5011 175.268 15.0015H173.147L174.977 11.8995H174.535L171.097 3.60368H172.942L175.671 10.1094L178.202 3.60368H180Z"
                fill="#000920"
            />
        </svg>
    );
};

export default LogoText;
