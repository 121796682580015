import React, { FC } from 'react';

interface Props {
    size?: number;
    className?: string;
}

const Hamburger: FC<Props> = ({
    size = 40,
    className = 'text-neutral-900',
}: Props) => {
    return (
        <svg
            className={`fill-current ${className}`}
            width={size}
            height={size}
            viewBox="20 14 40 52"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <g>
                <path d="M17 20.8682C15.3431 20.8682 14 22.2114 14 23.8682C14 25.5251 15.3431 26.8682 17 26.8682C44.6898 26.8682 33.4164 26.8682 63 26.8682C64.6568 26.8682 66 25.5251 66 23.8682C66 22.2114 64.6569 20.8682 63 20.8682C35.3102 20.8682 46.5836 20.8682 17 20.8682Z" />
                <path d="M17 53.1317C15.3431 53.1317 14 54.4749 14 56.1317C14 57.7886 15.3431 59.1317 17 59.1317C44.6898 59.1317 33.4164 59.1317 63 59.1317C64.6568 59.1317 66 57.7886 66 56.1317C66 54.4749 64.6569 53.1317 63 53.1317C35.3102 53.1317 46.5836 53.1317 17 53.1317Z" />
                <path d="M17 37.8682C15.3431 37.8682 14 39.2114 14 40.8682C14 42.5251 15.3431 43.8682 17 43.8682C44.6898 43.8682 33.4164 43.8682 63 43.8682C64.6568 43.8682 66 42.5251 66 40.8682C66 39.2114 64.6569 37.8682 63 37.8682C35.3102 37.8682 46.5836 37.8682 17 37.8682Z" />
            </g>
        </svg>
    );
};

export default Hamburger;
