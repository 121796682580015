import React, { FC, useContext } from 'react';
import Cross from '@/assets/icons/Cross';
import Hamburger from '@/assets/icons/Hamburger';
import { useTranslation } from 'react-i18next';
import { MenuContext } from '@/contexts/MenuProvider';

const BurgerMenuButton: FC = () => {
    const { t } = useTranslation();
    const { menuOpen, setMenuOpen } = useContext(MenuContext);

    const Icon = menuOpen ? Cross : Hamburger;
    const text = menuOpen ? t('dashboard:menuClose') : t('dashboard:menu');

    return (
        <button
            className="flex items-center overflow-hidden h-lg bg-primary-500 rounded-sm text-white text-3xsm px-base min-w-menu-button"
            onClick={() => setMenuOpen(!menuOpen)}
        >
            <Icon className="text-white mr-xsm" size={15} />
            <span className="h-full leading-menu-button ml-xsm">{text}</span>
        </button>
    );
};

export default BurgerMenuButton;
