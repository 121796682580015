import React, { FC } from 'react';

interface Props {
    className?: string;
}

const LogoSpinner: FC<Props> = ({ className }: Props) => {
    return (
        <svg
            viewBox="0 0 40 40"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className={`w-[30px] h-[30px] tablet:w-40px tablet:h-40px ${className}`}
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M9.8891 37.5883C11.7633 37.4601 13.4651 36.5698 14.6488 35.1246C15.8079 33.6795 16.3256 31.8127 16.079 29.9705C15.4132 25.8916 15.783 21.312 20 20.0222C20 20.0222 9.44508 18.7275 8.53262 36.1752C8.50796 36.5476 8.63133 36.9126 8.9026 37.1764C9.14921 37.4428 9.51918 37.5784 9.8891 37.5538C9.8891 37.5883 9.8891 37.5883 9.8891 37.5883Z"
                fill="#5081FF"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M0.419345 25.2923C1.82502 26.5155 3.67446 27.1 5.52403 26.9076C7.3736 26.7152 9.05058 25.7609 10.185 24.2763C12.6018 20.9273 16.1035 17.9458 19.9753 20.0173C19.9753 20.0173 13.4404 11.6399 0.44403 23.3268C0.172759 23.5685 0 23.9187 0 24.2911C0 24.6634 0.148098 25.0186 0.44403 25.2627C0.419369 25.2898 0.419345 25.2923 0.419345 25.2923Z"
                fill="#1D5CFF"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M2.41675 9.89643C2.54005 11.7534 3.42785 13.4723 4.88284 14.6461C6.31318 15.8175 8.18763 16.3354 10.0372 16.079C14.1063 15.418 18.693 15.783 19.9753 20.0001C19.9753 20.0001 21.2825 9.45007 3.82259 8.52774C3.45268 8.49815 3.08268 8.63132 2.81141 8.89272C2.5648 9.15413 2.41677 9.51666 2.44143 9.88904C2.41677 9.89397 2.41675 9.89397 2.41675 9.89643Z"
                fill="#5081FF"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M14.6978 0.419149C13.4894 1.82236 12.8978 3.66947 13.0951 5.51904C13.2924 7.37109 14.2294 9.0579 15.7337 10.1775C19.0629 12.5943 22.0468 16.0912 19.9753 19.9852C19.9753 19.9852 28.3601 13.4402 16.6708 0.4512C16.4242 0.167598 16.0789 0.00237208 15.709 -9.40287e-05C15.3391 -0.00256013 14.9938 0.15774 14.7472 0.438876C14.6979 0.416681 14.6978 0.419149 14.6978 0.419149Z"
                fill="#1D5CFF"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M30.1109 2.41418C30.1109 2.41418 30.1109 2.41665 30.1109 2.44625H30.1356C30.1603 2.44378 30.185 2.44378 30.2097 2.44378C30.5549 2.44871 30.8755 2.58435 31.0974 2.82356C31.3687 3.0899 31.4921 3.45488 31.4674 3.82479C30.6783 18.8212 22.7866 19.9728 20.5672 20H20.5181C20.1728 20.0024 20 19.9802 20 19.9802C24.217 18.6905 24.5869 14.1085 23.921 10.032C23.6744 8.1898 24.1925 6.32296 25.3515 4.87782C26.5353 3.43269 28.2367 2.53996 30.1109 2.41418Z"
                fill="#5081FF"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M39.581 14.7102C38.1753 13.4871 36.3255 12.9026 34.476 13.0949C32.6264 13.2873 30.9494 14.2392 29.815 15.7238C27.3982 19.0752 23.8965 22.0568 20.0247 19.9852C20.0247 19.9852 26.5599 28.3626 39.5563 16.6757C39.8275 16.4341 40 16.0814 40 15.709C40 15.3391 39.8522 14.984 39.5563 14.7398C39.5809 14.7127 39.581 14.7102 39.581 14.7102Z"
                fill="#1D5CFF"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M37.5833 30.1036C37.46 28.2491 36.5722 26.5278 35.1172 25.3564C33.6869 24.185 31.8127 23.6646 29.9632 23.9236C25.8941 24.5845 21.3071 24.217 20.0247 20C20.0247 20 18.7178 30.5525 36.1778 31.4723C36.5477 31.5019 36.9174 31.3712 37.1886 31.1098C37.4353 30.8484 37.5833 30.4834 37.5586 30.1135C37.5833 30.1086 37.5833 30.1061 37.5833 30.1036Z"
                fill="#5081FF"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M25.3022 39.5808C26.5106 38.1801 27.1025 36.333 26.9052 34.4809C26.7079 32.6313 25.7706 30.9445 24.2663 29.8249C20.9371 27.4081 17.9532 23.9087 20.0247 20.0172C20.0247 20.0172 11.6399 26.5598 23.3292 39.5512C23.5758 39.8348 23.9211 39.9976 24.291 40.0001C24.6609 40.0025 25.0062 39.8422 25.2528 39.5636C25.2775 39.5833 25.3022 39.5833 25.3022 39.5808Z"
                fill="#1D5CFF"
            />
        </svg>
    );
};

export default LogoSpinner;
