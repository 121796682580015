import React, { FC } from 'react';

interface Props {
    className?: string;
    height?: number;
    width?: number;
}

const ArrowUp: FC<Props> = ({
    height = 10,
    width = 16,
    className = 'text-neutral-600',
}) => {
    return (
        <svg
            className={`fill-current ${className}`}
            width={width}
            height={height}
            viewBox="0 0 16 10"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M0.802679 9.49693C1.29802 9.93723 2.0565 9.89261 2.49681 9.39727L7.99991 3.20627L13.503 9.39727C13.9433 9.89261 14.7018 9.93723 15.1972 9.49693C15.6925 9.05663 15.7371 8.29814 15.2968 7.8028L8.89681 0.602798C8.66909 0.346613 8.34268 0.200035 7.99991 0.200035C7.65715 0.200035 7.33074 0.346613 7.10302 0.602798L0.703024 7.8028C0.262722 8.29814 0.307339 9.05663 0.802679 9.49693Z"
            />
        </svg>
    );
};

export default ArrowUp;
