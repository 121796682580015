import Link from 'next/link';
import React, { FC } from 'react';
import LogoSpinner from '@/assets/icons/LogoSpinner';
import LogoText from '@/assets/icons/LogoText';
import BurgerMenuButton from '../dashboard/BurgerMenuButton';
import MainNavigation from './MainNavigation';
import TextInput from '../common/TextInput';
import Form from '../common/Form';
import Email from '@/assets/icons/Email';
import {
    websiteEmailAddress,
    websitePhoneNumber,
} from 'globals/companyInformation';
import Phone from '@/assets/icons/Phone';

interface Props {
    showSearch?: boolean;
    showSupportInfo?: boolean;
}

const Header: FC<Props> = ({ showSearch = false, showSupportInfo = false }) => {
    return (
        <div className="bg-white">
            <header className="flex items-center justify-between tablet:block px-md pt-md pb-md tablet:pb-0 tablet:px-md max-w-desktop mx-auto">
                <div className="flex space-x-4 justify-between items-center tablet:mb-md">
                    <Link href="/">
                        <a className="flex items-center">
                            <LogoSpinner className="mr-sm" />
                            <LogoText className="w-[200px] tablet:w-logo-width" />
                        </a>
                    </Link>

                    {showSupportInfo && (
                        <div className="hidden tablet:flex gap-lg text-2xsm">
                            <a
                                className="flex items-center gap-2xsm text-primary-700 font-thin"
                                href={`mailto:${websiteEmailAddress}`}
                            >
                                <Email className="w-[25px]" />
                                {websiteEmailAddress}
                            </a>

                            <a
                                className="flex items-center gap-2xsm text-primary-700 font-thin"
                                href={`tel:${websitePhoneNumber.raw}`}
                            >
                                <Phone className="w-[25px]" />
                                {websitePhoneNumber.display}
                            </a>
                        </div>
                    )}
                </div>

                {showSearch && (
                    <Form onSubmit={() => null} className="hidden tablet:block">
                        <TextInput
                            id="global-search"
                            name="global-search"
                            label="Search for products and services"
                            hideLabel
                            placeholder="Search for products and services"
                            required
                            bottomMargin="small"
                        />
                    </Form>
                )}

                <MainNavigation />

                <div className="block tablet:hidden">
                    <BurgerMenuButton />
                </div>
            </header>
        </div>
    );
};

export default Header;
