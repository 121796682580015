import ArrowDown from '@/assets/icons/ArrowDown';
import ArrowUp from '@/assets/icons/ArrowUp';
import { AppUserContext } from '@/contexts/AppUserProvider';
import { MenuContext } from '@/contexts/MenuProvider';
import { useAuth } from '@/services/user.service';
import { displayName } from '@/utils/strings';
import { Menu, MenuButton, MenuItem, MenuList } from '@reach/menu-button';
import Link from 'next/link';
import { useRouter } from 'next/router';
import React, { useContext, FC } from 'react';

const PrimaryLink = ({ children, href }) => {
    const { pathname } = useRouter();

    return (
        <Link href={href}>
            <a
                className={`w-full px-md border-b tablet:w-auto hover:bg-primary-100 hover:border-primary-100 hover:no-underline pt-sm pb-base2 text-neutral-800 inline-block font-medium
                    ${
                        pathname === href
                            ? 'bg-primary-100 border-primary-200 text-primary-700'
                            : 'border-white'
                    }
                `}
            >
                {children}
            </a>
        </Link>
    );
};

const UserDropdown = () => {
    const { user } = useContext(AppUserContext);
    const router = useRouter();
    const { signOut } = useAuth();

    return (
        <Menu>
            {({ isExpanded }) => (
                <>
                    <MenuButton className="relative w-full">
                        <div className="flex justify-between items-center px-md pt-sm pb-base2 text-secondary-700 bg-secondary-100 border-b border-secondary-200">
                            {user && (
                                <>
                                    <span className="mr-sm capitalize">
                                        {displayName(
                                            user.firstName,
                                            user.lastName,
                                        )}
                                    </span>
                                    {isExpanded ? <ArrowUp /> : <ArrowDown />}
                                </>
                            )}

                            {!user && (
                                <>
                                    <span className="mr-sm">Account</span>
                                    {isExpanded ? <ArrowUp /> : <ArrowDown />}
                                </>
                            )}
                        </div>
                        <MenuList
                            className="w-full absolute z-50 drop-shadow"
                            portal={false}
                        >
                            {user && (
                                <>
                                    <MenuItem
                                        className={`cursor-pointer text-left bg-white text-2xsm-compact px-md pt-base2 pb-base hover:bg-primary-100`}
                                        onSelect={() => router.push('/home')}
                                    >
                                        Dashboard
                                    </MenuItem>
                                    <MenuItem
                                        className={`cursor-pointer text-left bg-white text-2xsm-compact px-md pt-base2 pb-base hover:bg-primary-100`}
                                        onSelect={async () => {
                                            await signOut(true);

                                            router.push('/');
                                        }}
                                    >
                                        Sign out
                                    </MenuItem>
                                </>
                            )}

                            {!user && (
                                <MenuItem
                                    className={`cursor-pointer text-left bg-white text-2xsm-compact px-md pt-base2 pb-base hover:bg-primary-100`}
                                    onSelect={() => router.push('/sign-in')}
                                >
                                    Sign in
                                </MenuItem>
                            )}
                        </MenuList>
                    </MenuButton>
                </>
            )}
        </Menu>
    );
};

const MainNavigation: FC = () => {
    const { menuOpen } = useContext(MenuContext);

    return (
        <nav
            className={`flex justify-between w-full text-xsm
                ${
                    menuOpen
                        ? 'absolute flex-col top-[70px] left-0 right-0 bg-white'
                        : 'hidden tablet:flex'
                }
            `}
        >
            <div
                className={`
                    ${menuOpen ? 'flex flex-col tablet:block' : ''}
                `}
            >
                <PrimaryLink href="/">Home</PrimaryLink>
                <PrimaryLink href="/nhs-prescriptions">
                    NHS Prescriptions
                </PrimaryLink>
                <PrimaryLink href="/online-doctor">Online Doctor</PrimaryLink>
                <PrimaryLink href="/shop">Shop</PrimaryLink>
            </div>

            <div
                className={`
                    ${menuOpen ? 'flex flex-col' : ''}
                `}
            >
                <PrimaryLink href="/support">Support</PrimaryLink>
                <div className="w-full inline-block tablet:w-auto">
                    <UserDropdown />
                </div>
            </div>
        </nav>
    );
};

export default MainNavigation;
