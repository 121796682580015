import React, { FC } from 'react';

interface Props {
    className?: string;
    height?: number;
    width?: number;
}

const ArrowDown: FC<Props> = ({
    height = 10,
    width = 16,
    className = 'text-neutral-600',
}: Props) => {
    return (
        <svg
            className={`fill-current ${className}`}
            width={width}
            height={height}
            viewBox="0 0 16 10"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M0.802679 0.503073C1.29802 0.0627712 2.05651 0.107388 2.49681 0.602728L7.99992 6.79373L13.503 0.602728C13.9433 0.107388 14.7018 0.0627712 15.1972 0.503073C15.6925 0.943375 15.7371 1.70186 15.2968 2.1972L8.89681 9.39721C8.66909 9.65339 8.34268 9.79997 7.99992 9.79997C7.65715 9.79997 7.33075 9.65339 7.10303 9.39721L0.703024 2.1972C0.262722 1.70186 0.307339 0.943375 0.802679 0.503073Z"
            />
        </svg>
    );
};

export default ArrowDown;
